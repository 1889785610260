<template>
    <div class="admin_parent">
        <div class="clearfix admin_nav">
            <div class="navig_logo fl">
                <img src="./../../assets/img/logo.png" alt="">
            </div>
            <div class="fr navig_div">
                <div class="navig_title">
                    <h3 class="fl">{{this.$store.state.schName}} - 教师端</h3>
                    <div class="fl navig_divs">
                        <span>项目填报开始截止时间：{{this.$store.state.startTime}} —— {{this.$store.state.endTime}}</span>
                    </div>
                    <span class="fr" @click="exit()">
                        <el-button type="info" size="small" plain>退出</el-button>
                    </span>
                </div>
            </div>
        </div>
        <div class="clearfix admin_all">
            <div class="fl admin_left">
                <el-menu
                    :default-active="activePath"
                    class="el-menu-vertical-demo"
                    :unique-opened='true'
                    background-color="#545c64"
                    text-color="#fff"
                    active-text-color="#ffd04b">
                    <router-link to="ListofProjects">
                        <el-menu-item index="ListofProjects">
                            <i class="iconfont icon-xiangmuliebiao" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">项目列表</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="Teachspection">
                        <el-menu-item index="Teachspection">
                            <i class="iconfont icon-iconmoney" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">中期检查</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="Teacherreport">
                        <el-menu-item index="Teacherreport">
                            <i class="iconfont icon-xiangmuliebiao" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">结项报告</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="FundingTeachers">
                        <el-menu-item index="FundingTeachers">
                            <i class="iconfont icon-iconmoney" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">经费审核</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="teacherPassword">
                        <el-menu-item index="teacherPassword">
                            <i class="iconfont icon-zhanghaoguanli" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">修改密码</span>
                        </el-menu-item>
                    </router-link>
                </el-menu>
            </div>
            <div class="fr admin_right">
                <div class="bread_container">
                    <el-breadcrumb class="breadcrumb" separator="/">
                        <el-breadcrumb-item 
                            v-for='(name,index) in $route.meta'
                            :key='index'
                            >
                            {{name}}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="bread_view">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            activePath:'',
        }
    },
    methods: {
        exit(){
            this.$router.push({name:'login'})
        },
        getPath(){
            let currentPath = this.$route
            if(this.$route.name=="Teachersviewdetails"){
                this.activePath = 'ListofProjects'
            }else if(this.$route.name=="Viewteacherreport"){
                this.activePath = 'Teacherreport'
            }else{
                this.activePath = currentPath.name
            }
        },
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/admin.css';
</style>
<style>
.admin_left .el-menu{
    border-right: none;
}
.admin_left .el-submenu__title i{
    color: #fff;
}
.admin_left .el-menu-item i{
    color: #fff;
}
.admin_left .is-active{
    background: rgb(67, 74, 80)!important;
}
</style>